import customizeSwipers from "./modules/swipers.min.js";
import lottieAnimate from "./modules/lottie.min.js";
import { LazyLoad } from "./modules/lazy-load.min.js";

// Run before page load
function preLoad() {
  // Set scroll position
  document.documentElement.dataset.scroll = window.scrollY;
}

// Initialize page
function initPage() {
  // Initialize Swup
  let swup = new Swup({
    animateHistoryBrowsing: true,
    containers: ["#swup", "#site-navbar"],
  });

  // Init scroll monitor
  transitionCallback();

  // Set transition callback
  swup.hooks.on("content:replace", transitionCallback);

  // Reload iubenda iframes
  swup.hooks.on("page:view", function () {
    if ($(".iubenda-embed").length > 0) {
      location.reload();
    }
  });
}

// Callback called on swup transition
function transitionCallback() {
  // Reinitialize lottie behavior
  lottieAnimate();

  // Reinitialize swiper
  customizeSwipers();

  // Iubenda cleanup
  iubendaCleanup();

  // Reinitialize lazy load
  let lazyLoad = new LazyLoad();
  lazyLoad.init();

  // On scroll update scroll position
  $(document).on("scroll", function () {
    document.documentElement.dataset.scroll = window.scrollY;
  });

  // Hide/Show reCaptcha badge based on page
  if ($("#contact-form").length > 0) {
    $(".grecaptcha-badge").addClass("visible");
  } else {
    $(".grecaptcha-badge").removeClass("visible");
  }

  // Update html class with page name
  let path = window.location.pathname.split("/").pop();
  if (path == "") {
    path = "home";
  }
  $("html").attr("class", "page-loading swup-enabled " + path);

  // Setup machine page
  setupMachinePage();
}

function removePreloader() {
  // Remove preloader
  setTimeout(function () {
    $(".preloader").fadeOut(500, function () {
      $(this).remove();
    });
  }, 1000);
}

function iubendaCleanup() {
  // Remove iubenda cookie message
  setTimeout(function () {
    if (_iub.cs.consent.purposes !== undefined) {
      let purposes_iub = Object.keys(_iub.cs.consent.purposes);

      for (let i = 0; i < purposes_iub.length; i++) {
        if (_iub.cs.consent.purposes[purposes_iub[i]] == true) {
          // Hide cookie message for enabled purposes
          $(".content-before-consent-" + purposes_iub[i]).each(
            function (_, el) {
              el.style.display = "none";
            },
          );

          // Send events to GTM
          switch (purposes_iub[i]) {
            case "2":
              // Attivatore Tag Manager categoria Interazioni Semplici
              dataLayer.push({
                event: "iubenda-interazioni-semplici",
              });
              break;
            case "3":
              // Attivatore Tag Manager categoria Esperienza Migliorata
              dataLayer.push({
                event: "iubenda-esperienza-migliorata",
              });
              break;
            case "4":
              // Attivatore Tag Manager categoria Misurazione
              dataLayer.push({ event: "iubenda-misurazione" });
              break;
            case "5":
              // Attivatore Tag Manager categoria Pubblicità
              dataLayer.push({ event: "iubenda-pubblicita" });
              break;
          }
        } else {
          // Show cookie message for disabled purposes
          $(".content-before-consent-" + purposes_iub[i]).each(
            function (_, el) {
              el.style.display = "flex";
            },
          );
        }
      }
    } else {
      // Show cookie message for disabled purposes
      $(".content-before-consent").each(function (_, el) {
        el.style.display = "block";
      });
    }
    _iub.cs.api.activateSnippets();
  }, 500);
}

function setupMachinePage() {
  $(".table-component").each(function (_, el) {
    const MAX_ROWS = 8;
    let show_button = false;

    // Get table rows
    $(el)
      .find("tr")
      .each(function (i, row) {
        // Hide rows after MAX_ROWS
        if (i >= MAX_ROWS) {
          show_button = true;
          $(row).addClass("hidden");
        }
      });

    // Show button if needed
    if (show_button) {
      $(el).find(".show-more").first().removeClass("hidden");
    }
  });
}

// Initialize page
preLoad();
$(window).on("load", function () {
  initPage();
  removePreloader();
});
